<template>
  <div>
    <v-overlay :value="overlay" absolute style="z-index:999999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-toolbar flat color="white">
        <v-col cols="2">
          <v-select
            v-model="searchCuenta"
            clearable
            label="Cuenta bancaria"
            class="mt-3"
            :items="cuentasBancarias"
            item-text="fin_account_name"
            item-value="fin_account_id"
            @change="cargarLibroBanco(true)"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <date-picker
            class="mt-4"
            v-model="searchFecha"
            valueType="YYYY/MM/DD HH:MM"
            time-title-format="YYYY/MM/DD HH:MM"
            :show-second="false"
            @change="cargarLibroBanco(true)"
            :range="true"
            type="datetime"
            placeholder="Fecha trans."
            :confirm="true"
            confirm-text="Seleccionar"
            :show-time-panel="false"
          >
            <template v-slot:footer="{ emit }">
              <button
                class="mx-btn"
                @click="showTimeRangePanel = !showTimeRangePanel"
              >
                {{ showTimeRangePanel ? "Fecha" : "Tiempo" }}
              </button>
              <button class="mx-btn" @click="selectToDay(emit)">
                Hoy
              </button>
            </template>
          </date-picker>
        </v-col>
        <!-- <v-col cols="2">
          <v-checkbox
            v-model="depCaja"
            label="Sólo depósitos de caja"
            class="mt-4"
            @change="cargarLibroBanco(true)"
          ></v-checkbox>
        </v-col> -->
        
        <v-col cols="2">
          <v-select
            v-model="searchOrigen"
            label="Origen"
            class="mt-4"
            :items="origenes"
            item-value="origen_id"
            item-text="nombre"
            clearable
            @change="cargarLibroBanco(true)"
          ></v-select>
          </v-col>
          
      </v-toolbar>
      <v-row class="px-4">
        <v-col cols="2">
          <v-select
            v-model="searchTipo"
            clearable
            label="Tipo trasacción"
            class="mt-3"
            :items="tiposTransac"
            item-text="tipo"
            item-value="tipo_id"
            @change="cargarLibroBanco(true)"
          ></v-select>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-model="searchRef"
            append-icon="mdi-magnify"
            label="Referencia"
            class="mt-3"
            @keydown.enter="cargarLibroBanco(true)"
            @click:append="cargarLibroBanco(true)"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-model="searchMonto"
            append-icon="mdi-magnify"
            label="Monto"
            class="mt-3"
            @keydown.enter="cargarLibroBanco(true)"
            @click:append="cargarLibroBanco(true)"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-model="searchAsiento"
            append-icon="mdi-magnify"
            label="Asiento contable"
            single-line
            class="mt-3"
            @keydown.enter="cargarLibroBanco(true)"
            @click:append="cargarLibroBanco(true)"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-model="searchCaja"
            append-icon="mdi-magnify"
            label="Caja"
            single-line
            class="mt-3"
            @keydown.enter="cargarLibroBanco(true)"
            @click:append="cargarLibroBanco(true)"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="searchEstado"
            label="Estado"
            class="mt-4"
            :items="estados"
            item-value="estado_id"
            item-text="nombre"
            clearable
            @change="cargarLibroBanco(true)"
          ></v-select>          
        </v-col>
      </v-row>
      <v-toolbar flat color="white">
        <v-btn
          color="primary"
          small
          class="ma-2 white--text"
          @click="exportar()"
        >
          <v-icon left>mdi-microsoft-excel</v-icon>
          Exportar
        </v-btn>

        <v-btn
          color="primary"
          small
          class="ma-2 white--text"
          @click="nuevaTransaccion()"
        >
          <v-icon left>mdi-plus</v-icon>
          Ingresar transacción
        </v-btn>

      </v-toolbar>

      <v-row>
        <v-col md="12" sm="12">
          <v-data-table
            :headers="headersLibroBanco"
            :items="lista"
            :items-per-page="1000"
            :loading="loadingTable"
            hide-default-footer
            :item-class="colorFila"
            class="elevation-1"
            
            
          >
           <template v-slot:item.opcion="{item}">
                <v-btn 
                    icon                      
                    target="_blank"
                    @click="borrarFat(item)"
                >
                    <v-icon small color='red' >mdi-delete</v-icon>
                </v-btn>                               
            </template>

            <template v-slot:item.fin_account_trans_id="{ item }">
              <v-btn
              small
                text
                @click="editarTransaccion(item)"
                target="_blank"                
                title="Ver transacción"
              ><v-icon small left>mdi-eye</v-icon>
                {{item.fin_account_trans_id}}
              </v-btn>
              
            </template>

            <template v-slot:item.caja_id="{ item }">
              <v-btn
                v-if="item.caja_id && !item.caja_trans_id"
                icon
                target="_blank"
                :to="`/ver-caja/${item.caja_id}`"
                title="Ver Depósito en efectivo"
              >
                <v-icon small color="green">mdi-cash</v-icon>{{ item.caja_id }}
              </v-btn>

              <v-btn
                v-if="item.caja_id && item.caja_trans_id"
                icon
                target="_blank"
                :to="`/ver-caja/${item.caja_id}`"
                title="Ver depósito de cheque"
              >
                <v-icon small color="green">mdi-checkbook</v-icon
                >{{ item.caja_id }}
              </v-btn>
            </template>
            <template v-slot:item.payment_id="{ item }">
              <v-btn
                v-if="
                  item.payment_id &&
                    item.fin_account_trans_type_id == 'WITHDRAWAL'
                "
                icon
                target="_blank"
                :to="`/ver-pago-emitido/${item.payment_id}`"
                title="Ver pago saliente"
              >
                <v-icon small color="green">mdi-eye</v-icon
                >{{ item.payment_id }}
              </v-btn>
              <v-btn
                v-if="
                  item.payment_id && item.fin_account_trans_type_id == 'DEPOSIT'
                "
                icon
                target="_blank"
                :to="`/ver-pago/${item.payment_id}`"
                title="Ver pago entrante"
              >
                <v-icon small color="green">mdi-eye</v-icon
                >{{ item.payment_id }}
              </v-btn>
            </template>

            <template v-slot:item.referencia="{ item }">
              <span v-if="item.payment_id == null">{{ item.referencia }}</span>
              <span v-if="item.payment_id != null">{{
                item.payment_ref_num
              }}</span>
            </template>

            <template v-slot:item.acctg_trans_id="{ item }">
              <!-- <span v-if="item.payment_id == null">{{
                item.acctg_trans_id                
              }}</span> -->
              <v-btn target="_blank" text v-if="item.payment_id == null" small :href="`/asientoContableVista/${item.acctg_trans_id}`" > <v-icon small color="green">mdi-eye</v-icon
                >{{
                item.acctg_trans_id                
              }}</v-btn>

              <!-- <span v-if="item.payment_id != null">{{
                item.acctg_trans_id_pay
              }}</span> -->

              <v-btn target="_blank" text v-if="item.payment_id != null" small :href="`/asientoContableVista/${item.acctg_trans_id_pay}`" > <v-icon small color="green">mdi-eye</v-icon
                >{{
                item.acctg_trans_id_pay                
              }}</v-btn>
            </template>
            <template v-slot:footer>
              <v-pagination
                class="mt-10"
                v-model="currentPage"
                :length="pageCount"
                @input="handlePageChange"
                total-visible="10"
              ></v-pagination>
            </template>

            <!--  <template slot="body.append">
                    <tr class="pink--text">
                        <th class="title">Total</th>
                        <th class="title"></th>
                        <th class="title"></th>
                        <th class="title"></th>
                        <th class="title"></th>
                        <th class="title"></th>                        
                        <th class="title">{{suma('total')}}</th>
                        <th class="title">{{suma('retendio')}}</th>
                        <th class="title">{{suma('pagado')}}</th>                        
                        <th class="title" align="right">{{suma('saldo')}}</th>
                    </tr>
                </template> -->
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- dialogo de ingreso de transacciones bancarias -->
    <v-dialog v-model="dialogTransaccionBanco" width="600"> 
      <v-card>
        <v-form ref="formFat">
        <v-toolbar height="40" color="blue" dark flat>
          <v-toolbar-title>Transacción bancaria</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small dark icon @click="dialogTransaccionBanco= !dialogTransaccionBanco"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        
        <v-row class="pa-2 ma-2">
          <v-col cols="12" class="pa-0 ma-0">
            <v-select  
            v-model="datosFat.fin_account_id" 
            :rules="requiredRule"
            label="Cuenta bancaria" 
            item-text="fin_account_name"
            item-value="fin_account_id"
            :disabled="editarFat==true"
            :items="cuentasBancarias" ></v-select>
          </v-col>

          <v-col class="pa-0 ma-0" cols="12">
            <v-select  
              :rules="requiredRule"
              v-model="datosFat.fin_account_trans_type_id" 
              :items="tiposTransac" 
              item-text="tipo" 
              item-value="tipo_id" 
              :disabled="editarFat==true"
              label="Tipo de transacción"></v-select>
          </v-col>

          <v-col class="pa-0 ma-0" cols="12">
            <v-autocomplete 
              :rules="(editarFat==false ? requiredRule : [])"
              v-model="datosFat.gl_account_id" 
              :items="cuentasContables" 
              item-text="nombre_cuenta" 
              item-value="gl_account_id" 
              :disabled="editarFat==true"
              label="Cuenta contable">
            </v-autocomplete>
          </v-col>

          <v-col class="pa-0 ma-0" cols="12">
            <v-text-field 
            :rules="requiredRule"
            v-model="datosFat.transaction_date" 
            :disabled="editarFat==true"
            type="date" label="Fecha"></v-text-field>
          </v-col>

          <v-col v-if="datosFat.payment_id ==''" class="pa-0 ma-0" cols="12">
            <v-text-field             
              v-model="datosFat.referencia" 
              label="Referencia"></v-text-field>
          </v-col>

          <v-col v-if="datosFat.payment_id!=''" class="pa-0 ma-0" cols="12">
            <v-text-field             
              v-model="datosFat.payment_ref_num" 
              label="Referencia pago"></v-text-field>
          </v-col>

          <v-col class="pa-0 ma-0" cols="12">
            <v-textarea 
              label="Observaciones" 
              v-model="datosFat.comments" 
              rows="2"></v-textarea>
          </v-col>
          
          <v-col class="pa-0 ma-0" cols="12">
            <v-text-field 
            :disabled="editarFat==true"
             :rules="requiredRule"
              v-model="datosFat.amount" label="Monto"></v-text-field>
          </v-col>
        </v-row>
        
        <v-row class="pa-0 ma-0">
          <v-col class="pt-4 ma-0">
            <v-btn class="mx-4" dark small v-if="editarFat==false" color="green" @click="ingresarFat()"><v-icon>mdi-content-save</v-icon>Guardar</v-btn>
            <v-btn class="mx-4" dark small v-if="editarFat==true" color="green"  @click="modificarFat()"><v-icon>mdi-content-save</v-icon>Modificar</v-btn>
            <v-btn class="mx-4" dark small color="blue" @click="dialogTransaccionBanco= !dialogTransaccionBanco" ><v-icon>mdi-cancel</v-icon>Cancelar</v-btn>
          </v-col>
        </v-row>
      </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
  .conciliado {
    background-color: #b3f9d6;
  }
</style>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import Vue from "vue";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/es";

export default {
  name: "ConciliacionBancariaComponent",
  components: {
    DatePicker,
  },

  data: () => ({
    headers: [
      { text: "Opcion", value: "opcion", align: "center", width: 30 },
      { text: "Codigo", value: "codigo", width: 30 },
      { text: "Tipo", value: "tipo", width: 30 },
      { text: "Fecha", value: "fecha", width: 100 },

      { text: "Concepto", value: "concepto", align: "left", width: 120 },

      { text: "Documento", value: "documento" },
      { text: "Oficina", value: "oficina", align: "left" },
      { text: "Estado", value: "estado", align: "left" },
      { text: "Concilado por", value: "conciliado_por", align: "left" },
      { text: "Monto", value: "monto", align: "right" },
      { text: "Saldo", value: "saldo", align: "right" },
    ],
    lista: [],
    requiredRule: [(v) => !!v || "El campo es requerido"],
    headersLibroBanco: [
      { text: "Opcion", value: "opcion", align: "center", width: 100 },
      { text: "Transaccion", value: "fin_account_trans_id", width: 30 },
      { text: "Tipo trans.", value: "tipo_tran", width: 30 },
      { text: "Fecha trans.", value: "transaction_date", width: 100 },
      { text: "Fecha reg.", value: "entry_date", width: 100 },
      { text: "Monto", value: "amount", align: "right" },
      { text: "Referencia", value: "referencia", align: "left" },
      { text: "Pago", value: "payment_id" },
      { text: "Caja", value: "caja_id" },
      { text: "Asiento contable", value: "acctg_trans_id", align: "left" },
      { text: "Comentario", value: "comments", align: "left" },
    ],
    showTimeRangePanel: false,
    showTimePanel: false,
    options: {},
    dialog: false,
    notifications: false,
    sound: true,
    widgets: true,

    searchTipo: "",
    searchCaja: "",
    depCaja: false,

    currentPage: 1,
    pageCount: 1,
    itemsPerPage: 50,

    tipo: "",
    desde: "",
    hasta: "",
    //estado: "CREADO",
    searchRef: "",
    searchFecha: [],
    searchMonto: "",
    searchAsiento: "",
    searchCuenta: "",
    tipoTransac: "",
    tiposTransac: [
      { tipo_id: "DEPOSIT", tipo: "Depósito" },
      { tipo_id: "WITHDRAWAL", tipo: "Retiro" },
    ],
    cuentasBancarias: [],
    dialogTransaccionBanco: false,
    datosTransaccion: {},
    datosFat: {},

    cuentasContables: [],
    overlay:false,
    editarFat: false,

    estados: [
      { estado_id: "CONCILIADO", nombre: "Conciliado" },
      { estado_id: "NO_CONCILIADO", nombre: "No Conciliado" },
    ],

    origenes: [
      { origen_id: "PAGOS", nombre: "Pagos / Recibos" },
      { origen_id: "CAJA", nombre: "Depósitos de caja" },
      { origen_id: "LIBRO_BANCO", nombre: "Libro banco" },
    ],
    searchEstado: "",
    searchOrigen:""

  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "tenantId"]),
    ...mapGetters("access", ["btnAbrirCaja"]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setTitleToolbar",
      "setLoadingTable",
      "setDrawer",
    ]),
    ...mapActions("master", ["requestApi","alertNotification"]),
    
    colorFila(item) {
        if (item.gl_reconciliation_id) {
          return "conciliado";
        } 
    }
    ,
    cargarLibroBanco(filtrar) {
      if (filtrar == true) {
        this.currentPage = 1;
      }
      this.setLoadingTable(true);
      this.overlay=true;
      console.log(this.searchFecha);
      this.setUrl("libro-bancos");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
          page_count: this.pageCount,
          page_size: this.itemsPerPage,
          tipo: this.searchTipo,
          transac_desde: this.searchFecha[0],
          transac_hasta: this.searchFecha[1],
          estado: this.searchEstado,
          referencia: this.searchRef,
          monto: this.searchMonto,
          asiento: this.searchAsiento,
          fin_account_id: this.searchCuenta,
          caja_id: this.searchCaja,
          dep_caja: this.depCaja,
          origen:this.searchOrigen,

        },
      })
        .then((res) => {
          //console.log(res.data.lista);
          this.lista = res.data._embedded.libro_bancos;
          this.pageCount = res.data.page_count;
          this.setLoadingTable(false);
          this.overlay=false;
          this.dialogTransaccionBanco=false
        })
        .catch(() => {
          this.setLoadingTable(false);
          this.overlay=false;
        });
    },

    cargarListaEstadoCuenta(filtrar) {
      if (filtrar == true) {
        this.currentPage = 1;
      }
      this.setLoadingTable(true);

      this.setUrl("estado-cuenta");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
          page_count: this.pageCount,
          page_size: this.itemsPerPage,
          tipo: this.searchTipo,
          desde: this.searchFecha[0],
          hasta: this.searchFecha[1],
          estado: this.estado,
          referencia: this.referencia,
        },
      })
        .then((res) => {
          //console.log(res.data.lista);
          this.lista = res.data._embedded.estado_cuenta;
          //this.pageCount = res.data.page_count;
          this.setLoadingTable(false);
        })
        .then(() => {});
    },

    exportar() {
      this.setUrl("libro-bancos");
      this.requestApi({
        method: "POST",
        data: {
          accion: "exportarLibroBanco",
          tipo: this.searchTipo,
          transac_desde: this.searchFecha[0],
          transac_hasta: this.searchFecha[1],
          estado: this.estado,
          referencia: this.searchRef,
          monto: this.searchMonto,
          asiento: this.searchAsiento,
          fin_account_id: this.searchCuenta,
          caja_id: this.searchCaja,
          dep_caja: this.depCaja,
        },
      })
        .then((res) => {
          console.log(res.data);

          var a = document.createElement("a");
          a.href =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
            res.data.detail.datos;
          a.download = res.data.detail.archivo;
          a.click();
        })
        .then(() => {});
    },

    suma(col) {
      return this.lista
        .reduce(function(a, b) {
          if (b["signo"] == "pos") {
            return a + (b[col] || 0);
          } else {
            return a + (b[col] * -1 || 0);
          }
        }, 0)
        .toFixed(2);
    },

    cargarCuentasBancarias() {
      this.setLoadingTable(true);
      this.setUrl("libro-bancos");
      this.requestApi({
        method: "POST",
        data: {
          accion: "datosConciliacion"
        },
      }).then((res) => {
        this.cuentasBancarias = res.data.detail.cuentas_bancarias;
        this.cuentasContables = res.data.detail.cuentas_hijas;
        this.setLoadingTable(false);
      });
    },
    ingresarFat() {
      if(!this.$refs.formFat.validate()) {
        return false;
      }
      this.setUrl("libro-bancos");
      this.requestApi({
        method: "POST",
        data: {
          accion: "ingresarFat",
          datosFat: this.datosFat
        },
      })
        .then((res) => {          
          this.setLoadingTable(false);
          this.cargarLibroBanco(false);
        })
        .catch(() => {
          this.setLoadingTable(false);
          this.cargarLibroBanco(false);
        });
    },
    modificarFat() {
      
      if(!this.$refs.formFat.validate()) {        
        return false;
      }
      
      this.setUrl("libro-bancos");
      this.requestApi({
        method: "POST",
        data: {
          accion: "modificarFat",
          datosFat: this.datosFat
        },
      })
        .then((res) => {          
          this.setLoadingTable(false);
          this.cargarLibroBanco(false);
        })
        .catch(() => {
          this.setLoadingTable(false);
          this.cargarLibroBanco(false);
        });
    },
    handlePageChange(value) {
      //this.currentPage = value
      this.cargarLibroBanco(false);
    },

    nuevaTransaccion() {
      this.datosFat = {}
      this.editarFat = false
      this.dialogTransaccionBanco=true
    },

    editarTransaccion(item) {
      this.datosFat = item
      this.editarFat = true
      this.dialogTransaccionBanco=true
    },

    borrarFat(item) {
      Vue.swal({
                html: "Está seguro de eliminar esta transacción ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then( result => {

                if (result.isConfirmed) {
                   
                    this.overlay=true;
                    this.setUrl('libro-bancos')
                    this.requestApi({
                        method: 'POST',
                        data: {
                            accion:'anularFat',
                            fin_account_trans_id: item.fin_account_trans_id
                        }
                    }).then(res =>{
                        console.log(res)

                        this.alertNotification({
                            param:{
                                html: res.data.detail
                            }
                        })
                        this.overlay=false;
                        this.cargarLibroBanco(false)
                        

                    }).catch(()=>{
                      this.overlay=false;                        

                    })
        
                }
            })
    }

  },

  mounted() {
    this.setTitleToolbar("LIBRO BANCO");
    this.setDrawer(false)
    this.cargarLibroBanco(false);
    this.cargarCuentasBancarias();

  },

  watch: {
    searchNumber: {
      handler() {
        this.cargarLista(true);
      },
    },
    searchEstado: {
      handler() {
        this.cargarLista(true);
      },
    },
  },
};
</script>
